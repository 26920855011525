<template>
  <div>
    <div style="margin-bottom: 10px">
      <span>广告类型：</span>
      <el-select
        v-model="formatType"
        placeholder="选择广告类型"
      >
        <el-option
          :label="format.name"
          :value="format.id"
          v-for="format in formatList"
          :key="format.id"
        ></el-option>
      </el-select>
    </div>
    <p>
      <span>广告素材：</span>
      <span>
        <el-button
          size="small"
          type="primary"
          @click="addSource('img')"
          v-if="formatType !== 'SINGLE_VIDEO'"
          >添加图片</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="addSource('video')"
          v-if="formatType == 'SINGLE_VIDEO' || formatType == 'AUTOMATIC_FORMAT'"
          >添加视频</el-button
        >
      </span>
    </p>
    <!-- <div>sourceList:{{ sourceList }}</div> -->
    <!-- <div>fbAssetFeedSpec:{{ value.fbAssetFeedSpec }}</div> -->
    
    <div class="source">
      <div
        class="sourceItem"
        v-for="(source, index) in sourceList"
        :key="index"
      >
        <div v-if="source && source.type == 'img'">
          <el-button
            type="text"
            class="delete"
            icon="el-icon-circle-close"
            @click="deleSource(index)"
          ></el-button>
          <div
            class="imgCon"
            v-if="!source.url"
          >
            <el-button
              size="small"
              @click="selectImg('img', index)"
              >从创意云选择图片</el-button
            >
            <!-- <el-upload
              class="upload-demo"
              action="#"
              :http-request="upload_img"
              :show-file-list="false"
              :before-upload="beforeUpload"
            >
              <el-button
                size="small"
                type="primary"
                @click="uploadType('img', index)"
                >点击上传图片</el-button
              >
              <div
                slot="tip"
                class="el-upload__tip"
                style="line-height: 28px"
              >
                <p>推荐长宽比：1:1</p>
                <p>推荐尺寸：1080 * 1080像素</p>
                <p>最小尺寸：600 * 600像素</p>
                <p>图中文本内容不超过20%效果更佳</p>
              </div>
            </el-upload> -->
            <gm-upload-material
              ref="uploadMaterial"
              v-model:fileList="fileList"
              :saveAdFileParams="saveAdFileParam"
              :on-success="onSuccess"
              :before-upload="beforeUpload"
            >
              <el-button
                size="small"
                type="primary"
                >点击上传图片</el-button
              >
              <div
                slot="tip"
                class="el-upload__tip"
              >
                <img-tips></img-tips>
              </div>
            </gm-upload-material>
          </div>
          <div
            class="imgCon"
            v-else
          >
            <div class="tools">
              <!-- <i class="el-icon-info"></i> -->
              <i class="el-icon-zoom-in"></i>
              <i
                class="el-icon-delete"
                @click="deleImg(index)"
              ></i>
              <!-- <el-upload
                class="upload-demo"
                action="#"
                :http-request="upload_img"
                :show-file-list="false"
                style="display: inline-block"
                :before-upload="beforeUpload"
              >
                <i
                  class="el-icon-upload2"
                  @click="uploadType('img', index)"
                ></i>
              </el-upload> -->
              <gm-upload-material
                class="inline-block"
                ref="uploadMaterial"
                v-model:fileList="fileList"
                :saveAdFileParams="saveAdFileParam"
                :on-success="onSuccess"
                :before-upload="beforeUpload"
              >
                <i
                  class="el-icon-upload2"
                  @click="uploadType('img', index)"
                ></i>
              </gm-upload-material>
            </div>
            <div class="imgTips">
              <p>推荐长宽比：1:1</p>
              <p>推荐尺寸：1080 * 1080像素</p>
              <p>最小尺寸：600 * 600像素</p>
              <p>图中文本内容不超过20%效果更佳</p>
            </div>
            <el-image
              :src="source.url"
              :preview-src-list="[source.url]"
              fit="contain"
            ></el-image>
          </div>
        </div>
        <div v-if="source && source.type == 'video'">
          <upload-video
            :value="value"
            :uploadTypes="uploadTypes"
            :index="index"
            @deleSource="deleSource"
            @emitCreative="emitCreative"
            @setSource="setSource"
            :source="source"
            :edit="edit"
          ></upload-video>
        </div>
      </div>
    </div>
    <p>广告标题：</p>
    <div>
      <el-row
        :gutter="10"
        v-for="(title, i) in titleList"
        :key="i"
        class="sourceTextItem"
      >
        <el-col :span="20">
          <emoji-input
            palce="请输入标题"
            type="text"
            :value="titleList[i]"
            @input="setTitle"
            :index="i"
            :class="[titleList.filter((v) => v == titleList[i]).length >= 2 ? 'redBorder' : 'sss']"
          ></emoji-input>
        </el-col>
        <el-row :span="4">
          <el-button
            icon="el-icon-minus"
            type="text"
            @click="deleItem('titleList', i)"
            v-if="titleList.length > 1"
          ></el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            @click="addItem('titleList')"
            v-if="i == titleList.length - 1 && titleList.length < 5"
          ></el-button>
        </el-row>
      </el-row>
    </div>
    <p>广告描述：</p>
    <div>
      <el-row
        :gutter="10"
        v-for="(discri, i) in discriList"
        :key="i"
        class="sourceTextItem"
      >
        <el-col :span="20">
          <emoji-input
            palce="请输入描述"
            type="text"
            :value="discriList[i]"
            :index="i"
            @input="setDiscrib"
            :class="[discriList.filter((v) => v == discriList[i]).length >= 2 ? 'redBorder' : 'sss']"
          ></emoji-input>
        </el-col>
        <el-row :span="4">
          <el-button
            icon="el-icon-minus"
            type="text"
            @click="deleItem('discriList', i)"
            v-if="discriList.length > 1"
          ></el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            @click="addItem('discriList')"
            v-if="i == discriList.length - 1 && discriList.length < 5"
          ></el-button>
        </el-row>
      </el-row>
    </div>
    <p>广告正文：</p>
    <div>
      <el-row
        :gutter="10"
        v-for="(discri, i) in messageList"
        :key="i"
        class="sourceTextItem"
      >
        <el-col :span="20">
          <emoji-input
            palce="请输入正文"
            type="textarea"
            :value="messageList[i]"
            @input="setMessage"
            :class="[messageList.filter((v) => v == messageList[i]).length >= 2 ? 'redBorder' : 'sss']"
            :index="i"
          ></emoji-input>
        </el-col>
        <el-row :span="4">
          <el-button
            icon="el-icon-minus"
            type="text"
            @click="deleItem('messageList', i)"
            v-if="messageList.length > 1"
          ></el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            @click="addItem('messageList')"
            v-if="i == messageList.length - 1 && messageList.length < 5"
          ></el-button>
        </el-row>
      </el-row>
    </div>
    <img-list
      :imgListShow="imgListShow"
      :uploadTypes="uploadTypes"
      :saveAdFileParam="saveAdFileParam"
      @close="close"
      @urlList="urlList"
    ></img-list>
  </div>
</template>
<script>
import imgList from '../imgList';
import adsMethods from '../../adsMethods.js';
import emojiInput from '../emojiInput';
import uploadVideo from './uploadVideo.vue';
import imgTips from '@/views/adManagement/createAd/components/adsComponents/imgTips.vue';
export default {
  mixins: [adsMethods],
  components: {
    imgList,
    emojiInput,
    uploadVideo,
    imgTips,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    //是否是编辑
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatList: [
        { id: 'SINGLE_IMAGE', name: '动态图片(只能上传图片)' },
        { id: 'SINGLE_VIDEO', name: '动态视频(只能上传视频)' },
        { id: 'CAROUSEL_IMAGE', name: '轮播图(只能上传图片，并且最少两张)' },
        { id: 'AUTOMATIC_FORMAT', name: '自动格式(可以上传图片或者视频)' },
      ],
      // formatType: ["AUTOMATIC_FORMAT"],
      // sourceList: [],
      uploadIndex: 0,
      uploadTypes: '',
      imgListShow: false,
      // titleList: [""],
      // discriList: [""],
      // messageList: [""],
    };
  },
  computed: {
    formatType: {
      get() {
        if (!this.value.fbAssetFeedSpec) {
          return 'AUTOMATIC_FORMAT';
        }
        console.log(this.value.fbAssetFeedSpec.mAdFormats);
        return this.value.fbAssetFeedSpec.mAdFormats ? this.value.fbAssetFeedSpec.mAdFormats[0] : 'AUTOMATIC_FORMAT';
      },
      set(val) {
        console.log(val);
        this.emitCreative('formatType', val);
      },
    },
    sourceList: {
      get() {
        if (!this.value.fbAssetFeedSpec) {
          return [{}];
        }
        let creative = JSON.parse(JSON.stringify(this.value.fbAssetFeedSpec));
        console.log(creative,'creativecreative');
        
        let imgList = creative.imgList || [];
        let videos = creative.videos || [];
        imgList.map((v) => {
          v.type = 'img';
        });
        videos.map((v) => {
          v.type = 'video';
        });
        return imgList.concat(videos);
      },
      set(val) {
        this.emitCreative('sourceList', val);
      },
    },
    titleList: {
      get() {
        if (!this.value.fbAssetFeedSpec) {
          return [''];
        }
        return this.value.fbAssetFeedSpec.titles.length ? this.value.fbAssetFeedSpec.titles : [''];
      },
      set(val) {
        this.emitCreative('titleList', val);
      },
    },
    discriList: {
      get() {
        if (!this.value.fbAssetFeedSpec) {
          return [''];
        }
        return this.value.fbAssetFeedSpec.mDescriptions.length ? this.value.fbAssetFeedSpec.mDescriptions : [''];
      },
      set(val) {
        this.emitCreative('discriList', val);
      },
    },
    messageList: {
      get() {
        if (!this.value.fbAssetFeedSpec) {
          return [''];
        }
        if (!this.value.fbAssetFeedSpec?.mBodies?.length) {
          this.value.fbAssetFeedSpec.mBodies = [''];
        }
        return this.value.fbAssetFeedSpec.mBodies.length ? this.value.fbAssetFeedSpec.mBodies : [''];
      },
      set(val) {
        console.log(val);
        this.emitCreative('messageList', val);
      },
    },
  },
  methods: {
    //关闭创意云
    close(val) {
      this[val] = false;
    },
    // 从创意云选择图片
    selectImg(type, index) {
      this.uploadTypes = type;
      this.uploadIndex = index;
      this.imgListShow = true;
    },
    // 获取创意云选择的图片
    urlList(v) {
      console.log(v,'urlList');
      
      let img = v[v.length - 1];
      this.sourceList[this.uploadIndex].url = img.url;
      this.sourceList[this.uploadIndex].source_id = img.id;
      this.sourceList[this.uploadIndex].sourceType = img.sourceType;
      this.emitCreative();
    },
    uploadType(type, index) {
      this.uploadTypes = type;
      this.uploadIndex = index;
    },
    addSource(type) {
      this.uploadTypes = type;
      if (this.sourceList.some((v) => !v.url)) {
        this.$message({
          type: 'warning',
          message: '请先完善已经添加的素材，然后再新增',
        });
        return false;
      }
      this.sourceList.push({
        url: '',
        source_id: null,
        thumbUrl: '',
        type: type,
        videoStatus: 'ready',
      });
      console.log(this.sourceList,'this.sourceList');
      this.emitCreative();
    },
    // upload_img(file) {
    //   this.uploadImg(file).then((data) => {
    //     console.log(data);
    //     this.sourceList[this.uploadIndex].url = data.url;
    //     this.sourceList[this.uploadIndex].source_id = data.id;
    //     console.log(this.sourceList[this.uploadIndex].url);
    //     this.emitCreative();
    //   });
    // },
    onSuccess(response) {
      console.log(response, 'response');
      this.sourceList[this.uploadIndex].url = response.url;
      this.sourceList[this.uploadIndex].source_id = response.id;
      this.sourceList[this.uploadIndex].sourceType = response.sourceType;
      this.emitCreative();
    },
    deleImg(index) {
      this.sourceList[index].url = '';
      this.sourceList[index].source_id = null;
      this.sourceList[index].type = this.sourceList[index].type;
      this.emitCreative();
    },
    deleSource(index) {
      this.sourceList.splice(index, 1);
      this.emitCreative();
      if (!this.sourceList.length) {
        this.uploadTypes = '';
      }
    },
    setTitle(val, index) {
      this.titleList.splice(index, 1, val);
    },
    setDiscrib(val, index) {
      this.discriList.splice(index, 1, val);
    },
    setMessage(val, index) {
      if(this.messageList.length === 0){
        this.messageList.push(val);
        return
      }
      this.messageList.splice(index, 1, val);
    },
    // 添加标题
    addItem(type) {
      this[type].push('');
    },
    deleItem(type, index) {
      this[type].splice(index, 1);
      if (this[type][index]) {
        this.emitCreative();
      }
    },
    setFormatType(v) {
      console.log(v);
      this.formatType = v;
      if (v == 'SINGLE_VIDEO') {
        this.sourceList = this.sourceList.filter((v) => v.type == 'video');
      }
      // if()
      // this.emitCreative();
    },
    // 给video设置值
    setSource(index, v) {
      this.sourceList[index] = { ...v };
      this.emitCreative();
    },
    // 回传creative
    emitCreative(key, val) {
      // if(key == 'formatType'){
      //   this.formatType = val;
      // }
      let creative = JSON.parse(JSON.stringify(this.value));
      console.log(val,this.sourceList,'val');
      let fbAssetFeedSpec = {};

      // fbAssetFeedSpec.titles = this.titleList.filter(v=>v);
      // fbAssetFeedSpec.mDescriptions = this.discriList.filter(v=>v);
      // fbAssetFeedSpec.mBodies = this.messageList.filter(v=>v);
      fbAssetFeedSpec.imgList = this.sourceList
        .filter((v) => v.type == 'img')
        .map((v) => {
          // if(v.source_id && v.url){
          return { source_id: v.source_id, url: v.url , mediaSourceType: v.sourceType || v.mediaSourceType};
        });
      fbAssetFeedSpec.videos = this.sourceList
        .filter((v) => v.type == 'video')
        .map((v) => {
          // if(v.source_id && v.thumbUrl){
          return { source_id: v.source_id, url: v.url, mediaSourceType: v.sourceType || v.mediaSourceType, thumbUrl: v.thumbUrl,videoStatus: v.videoStatus };
          // }
        });
      if (key == 'formatType') {
        fbAssetFeedSpec.mAdFormats = [val];
        if (val == 'SINGLE_VIDEO') {
          fbAssetFeedSpec.imgList = [];
        }
        if (val == 'SINGLE_IMAGE' || val == 'CAROUSEL_IMAGE') {
          fbAssetFeedSpec.videos = [];
        }
      }
      // fbAssetFeedSpec.mAdFormats = [...this.formatType]
      creative.fbAssetFeedSpec = { ...creative.fbAssetFeedSpec, ...fbAssetFeedSpec };
      this.$emit('input', creative);
    },
  },
};
</script>
<style lang="scss">
.emojiInputWrap {
  &.redBorder {
    .el-input__inner,
    .el-textarea__inner {
      border: 1px solid #ff0000;
    }
  }
}
.el-row {
  position: static;
}
.delete {
  position: absolute;
  top: -20px;
  right: -5px;
}
.sourceTextItem {
  margin-bottom: 10px;
}
.source {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  .sourceItem {
    width: 248px;
    height: 248px;
    border: 1px dashed #ddd;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    position: relative;
    margin: 10px;

    .imgCon {
      width: 208px;
      height: 208px;
    }
    .el-iamge {
      width: 100%;
      height: 100%;
    }
    &.addSource {
      padding-top: 50px;
    }
    .imgTips {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba($color: #000000, $alpha: 0.6);
      z-index: 222;
      padding: 10px;
      width: 100%;
      display: none;
      p {
        font-size: 12px;
        color: #fff;
        line-height: 1.5;
      }
    }
    &:hover {
      .tools {
        display: block;
        .el-icon-info {
          &:hover {
          }
        }
      }
    }

    .tools {
      position: absolute;
      top: 0;
      right: 8px;
      z-index: 22;
      display: none;
      i {
        font-size: 16px;
        margin: 5px;
        cursor: pointer;
      }
    }
    .el-image,
    video {
      width: 100%;
      height: 100%;
    }
    .el-upload__tip {
      text-align: left;
    }
  }
}
</style>
